import Vue from 'vue'

export const saveResourcesToStore = (state, data) => {
  data.forEach((newResource) => {
    saveResourceToStore(state, newResource)
  })
}

export const saveResourceToStore = (state, newResource) => {
  const index = state.resources.findIndex((resource) => {
    return resource.id === newResource.id
  })

  if (index > -1) {
    // 不能使用 state.resources[index] = newResource，否則有些監聽 state.resources 將無法被啟動

    Vue.set(state.resources, index, newResource)
    // state.resources[index] = newResource
  } else {
    state.resources.push(newResource)
  }
}
